import React from 'react';

import Layout from '../components/layout/layout';
import HomeHero from '../components/home-hero/home-hero';
import './index.scss';
import directriceImg from '../images/directrice.png';
import HomeOurActions from '../components/home-our-actions/home-our-actions';
import HomeCallout from '../components/home-sponsorship/home-sponsorship';

const IndexPage = () => (
  <Layout>
    <HomeHero/>
    <main className="main container mat-elevation-z15 mb-3">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 px-1">
          <blockquote>
            <p>
              Notre vision est de faire du Centre Papillon Bleu la structure qui répond le plus aux besoins des enfants
              déficients mentaux et handicapés moteurs au Sénégal.
            </p>
            <p>
              Nous nous sommes engagés à tout mettre en oeuvre pour améliorer la qualité de notre encadrement, notre
              plaidoyer, afin que les enfants défavorisés bénéficient de soins de santé gratuits et aient une meilleure
              place dans la société.
            </p>
            <span>
              Nancy Diakhaté Diop, <br/>
              Directrice du centre Papillon Bleu
            </span>
          </blockquote>
        </div>
        <figure className="col-12 col-md-6 px-1">
          <img className="w-100 rounded" src={directriceImg} alt="Présidente Papillon Bleu"/>
          <figcaption className="text-right">Nancy Diakhaté Diop</figcaption>
        </figure>
      </div>
      <HomeCallout
        link={{
          text: 'Devenir parrain',
          to: '/contact'
        }}
        title="Parrainez un Enfant"
        body="Aider les enfants handicapés à améliorer leur évolution sociale, c'est aider le Centre Papillon Bleu à créer un
        monde différent pour ces enfants"
      />
      <HomeOurActions/>
      <HomeCallout
        link={{
          text: 'Contactez nous',
          to: '/contact'
        }}
        title="Des questions sur le Centre Papillon Bleu ?"
      />
    </main>
  </Layout>
);

export default IndexPage;
