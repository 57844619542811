import React from 'react';
import './home-our-action.scss';
import sortiesImageUrl from '../../images/sorties.jpg';
import recreativeImageUrl from '../../images/recreative.jpg';
import bonheurImageUrl from '../../images/bonheur.jpg';
import materialImageUrl from '../../images/materiel.jpg';
import educationImageUrl from '../../images/education.jpg';

const actions = [
  {
    title: 'Éducation des enfants',
    image: {
      src: educationImageUrl,
      alt: 'Education'
    },
    body: `Le centre organise des cours pour les enfants, du pré-scolaire à la primaire.`
  },
  {
    title: 'Fourniture de matériels',
    image: {
      src: materialImageUrl,
      alt: 'CEO'
    },
    body: `Nous fournissons aux enfants des matériels nécessaires à leur état de santé (fauteuils roulants,
          béquilles).
        `
  },
  {
    title: 'Bonheur des enfants',
    image: {
      src: bonheurImageUrl,
      alt: 'Epanouissement'
    },
    body: `Le handicap ne doit en aucun cas être un obstacle au bonheur des enfants, ni à leur ouverture au monde.`
  },
  {
    title: 'Sorties et découvertes',
    image: {
      src: sortiesImageUrl,
      alt: 'Epanouissement'
    },
    body: `Les enfants du Centre Papillon Bleu font des sorties scolaires pour s'ouvrir davantage et de mieux s'exprimer.`
  },
  {
    title: 'Journées récréatives',
    image: {
      src: recreativeImageUrl,
      alt: 'Epanouissement'
    },
    body: `Des journées récréatives (Mardi gras, Noël) et des activités sportives y sont également organisées.`
  }
  /*{
    title: 'Journées de consultation',
    image: {
      src: materialImageUrl,
      alt: 'Enfant'
    },
    body: `Le centre organise des journées de consultation qui permettent de déceler certaines maladies`
  },
  {
    title: 'Prise en charge médicale',
    image: {
      src: materialImageUrl,
      alt: 'Epanouissement'
    },
    body: `Nous participons à l'amélioration de la santé des enfants en leur faisant accéder aux soins médicaux`
  },*/
];

const HomeOurActions = () => (
  <div>
    <h2 className="pt-5">Nos actions</h2>
    <div className="row">
      {actions.map((action, index) => (
        <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h4 className="card-title">{action.title}</h4>
              <p className="card-text">{action.body}</p>
            </div>
            <img className="card-img" src={action.image.src} alt={action.image.alt} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default HomeOurActions;
