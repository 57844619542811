import React from 'react';
import './home-hero.scss';
import { Link } from 'gatsby';

const HomeHero = () => (
  <div className="home-hero d-flex align-items-center justify-content-center text-white">
    <div className="container">
      <div className="row text-center">
        <div className="col-md-8 mx-auto ">
          <h1>Centre Papillon Bleu</h1>
          <h3>Tous pour l'éducation inclusive</h3>
        </div>
        <div className="col-md-8 mx-auto">
          <button className="btn btn-secondary">
            <Link className="text-dark" to="/contact">Faire un don</Link>
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default HomeHero;
