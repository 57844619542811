import React from 'react';
import { Link } from 'gatsby';

const HomeSponsorship = ({ title, body, link }) => (
  <div className="row bg-primary">
    <div className="col-12 mb-3 py-5 text-center">
      <h4 className="text-white">{title}</h4>
      {body ? <p className="text-white"> {body} </p> : null}

      <button className="btn btn-secondary">
        <Link className="text-uppercase text-dark" to={link.to}>
          {link.text}
        </Link>
      </button>
    </div>
  </div>
);

export default HomeSponsorship;
